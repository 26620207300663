body {
  padding: 0;
  margin: 0 auto;
  width: auto;
  font:
    14px 'Lucida Grande',
    Helvetica,
    Arial,
    sans-serif;
  background: #333;
  color: #fff;
  text-shadow: 2px 1px 6px #000;
  background-image: url(../images/bg-texture.png);
  background-image: url(../images/bg-texture.png), linear-gradient(to bottom, #333, #252525);
  /* box-sizing: border-box; */
  padding: 0;
  height: 100%;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
