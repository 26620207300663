body {
  color: #fff;
  text-shadow: 2px 1px 6px #000;
  background-color: #333;
  background-image: url("bg-texture.e48ecc2b.png"), linear-gradient(#333, #252525);
  background-position: 0 0;
  background-repeat: repeat;
  background-size: auto;
  background-attachment: scroll;
  background-origin: padding-box;
  background-clip: border-box;
  width: auto;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  font: 14px Lucida Grande, Helvetica, Arial, sans-serif;
}

*, :before, :after {
  box-sizing: inherit;
}

/*# sourceMappingURL=index.fe7f488c.css.map */
